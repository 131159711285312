<template>
    <div class="center">
        <ul>
            <li>
                <div class="sharing_tit">首页</div>
                <el-row class="link_box">
                    <div class="simtit">员工管理</div>
                    <div class="simcon">
                        <el-col :span="6" v-for="(item, index) in items">
                            <div class="list">
                                <router-link :to="item.linkinfo">
                                <img :src="item.imageUrl"/>
                                <p>{{ item.message }}</p>
                                </router-link>
                            </div>
                        </el-col>
                    </div>
                </el-row>
            </li>
            <li>
                <el-row class="link_box">
                    <div class="simtit">招录管理</div>
                    <div class="simcon">
                        <el-col :span="6" v-for="(item, index) in itemszl">
                            <div class="list">
                                <router-link :to="item.linkinfo">
                                <img :src="item.imageUrl" />
                                <p>{{ item.message }}</p>
                                </router-link>
                            </div>
                        </el-col>
                    </div>
                </el-row>
            </li>
            <li>
                <el-row class="link_box">
                    <div class="simtit">奖惩管理</div>
                    <div class="simcon">
                        <div class="tit"><span></span>奖励</div>
                        <div class="contact">
                            <el-col :span="6" v-for="(item, index) in itemsjc">
                                <div class="list">
                                    <router-link :to="{name:item.linkinfo,params: {type:item.type}}">
                                    <img :src="item.imageUrl"/>
                                    <p>{{ item.message }}</p>
                                    </router-link>
                                </div>
                            </el-col>
                        </div>

                        <div class="tit"><span class="cf"></span>处罚</div>
                        <div class="contact">
                            <el-col :span="6" v-for="(item, index) in itemscf">
                                <div class="list">
                                    <router-link :to="{name:item.linkinfo,params: {type:item.type}}">
                                    <img :src="item.imageUrl"/>
                                    <p>{{ item.message }}</p>
                                    </router-link>
                                </div>
                            </el-col>
                        </div>
                    </div>
                </el-row>
                <!-- <el-row class="link_box">
                    <el-col :span="6" v-for="(item, index) in itemscf">
                        <router-link :to="{name:item.linkinfo,params: {type:item.type}}">
                        <img :src="item.imageUrl" height="50" width="50"/>
                            {{ item.message }}
                        </router-link>
                    </el-col>
                </el-row> -->
            </li>
        </ul>
        
    </div>
    
</template>

<script>
import {
  UserInfoGetInfoSharingCenter,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";

import UploadFiles from "../../components/UploadFiles";
export default {
    components: { UploadFiles },
  data() {
  	return {
    	items: 
        [
            { 
                message: '团队人员信息' ,
                imageUrl: require("./assets/icon_01.png"),
                linkinfo:'teaminfo'
            }, 
            { 
                message: '岗位及编制管理' ,
                imageUrl: require("./assets/icon_02.png"),
                linkinfo:'postInfo'
            }
        ],
        itemszl: 
        [
            { 
                message: '招聘需求管理' ,
                imageUrl: require("./assets/icon_03.png"),
                linkinfo:'Recruitment'
            }, 
            { 
                message: '面试及录用管理' ,
                imageUrl: require("./assets/icon_04.png"),
                linkinfo:'SalaInterview'
            },
            { 
                message: '试用及转正管理' ,
                imageUrl: require("./assets/icon_05.png"),
                linkinfo:'Positive'
            }, 
            { 
                message: '调岗管理' ,
                imageUrl: require("./assets/icon_06.png"),
                linkinfo:'TransferCenter'
            }
        ],
        itemsjc: 
        [   //SecondaryRewardType
            { 
                message: '现金奖励' ,
                imageUrl: require("./assets/icon_07.png"),
                linkinfo:'RewardsCenter',
                type:'现金奖励'
            }, 
            { 
                message: '荣誉奖励' ,
                imageUrl: require("./assets/icon_08.png"),
                linkinfo:'RewardsCenter',
                type:'荣誉奖励'
            },
            { 
                message: '晋升奖励' ,
                imageUrl: require("./assets/icon_09.png"),
                linkinfo:'RewardsCenter',
                type:'晋升奖励'
            }
        ],
        itemscf: 
        [
            { 
                message: '通报处罚' ,
                imageUrl: require("./assets/icon_10.png"),
                linkinfo:'RewardsCenter',
                type:'通报处罚'
            }, 
            { 
                message: '现金处罚' ,
                imageUrl: require("./assets/icon_11.png"),
                linkinfo:'RewardsCenter',
                type:'现金处罚'
            },
            { 
                message: '降职处罚' ,
                imageUrl: require("./assets/icon_12.png"),
                linkinfo:'RewardsCenter',
                type:'降职处罚'
            },
            { 
                message: '待岗处罚' ,
                imageUrl: require("./assets/icon_13.png"),
                linkinfo:'RewardsCenter',
                type:'待岗处罚'
            }
        ]
  	}
	},
    methods: {
        async login() {
            this.$message.success("Login Success 登录成功");
        },
        getData() {
            let para = {
                page: this.page,
                size: this.pageSize,
            };
            console.log(para);
            UserInfoGetInfoSharingCenter(para).then((res) => {
                this.total = res.data.response.pageCount;
                this.totaldata = res.data.response.dataCount;
                this.users = res.data.response.data;
                this.loading = false;

            });
        },
    },
    mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
}
</script>

<style>

.center{
    padding: 20px;
    overflow-y: auto;
    background-color: rgba(242,242,242);
}
.center ul li{
    margin-bottom: 36px;
}
.center .sharing_tit{
    /* background-color: #f2f2f2; */
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    margin-bottom: 20px;
}
.link_box{
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #d3d3d3;
}
.link_box .simtit{
    text-align: left;
    padding: 0 0 0 10px;
    border-left: 6px solid #ee4d68;
    font-weight: bold;
}
.link_box .simcon{
    overflow: hidden;
    border-top: 1px solid #ececec;
    margin-top: 14px;
}
.link_box .list{
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    width: 80%;
    margin: 20px 0 0 10px;
    padding: 20px 0;
}
.link_box .list img{
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}
.link_box .list p{
    display: inline-block;
    vertical-align: middle;
    line-height: 31px;
    border-left: 1px solid #e3e3e3;
    padding-left: 20px;
    width: 62%;
    text-align: left;
}
.link_box .list a{
    color: #000;
    display: block;
}
.center .tit{
    padding: 20px 10px 0;
    text-align: left;
} 
.center .tit span{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fc1717;
    display: inline-block;
    margin-right: 6px;
}
.center .tit .cf{
    background-color: #7b8afd;
}
.contact{
    overflow: hidden;
}
</style>